import React from 'react'
import { graphql, Link } from 'gatsby'
// import Img from 'gatsby-image'
import { GatsbyImage } from 'gatsby-plugin-image'

import { simplePageContainer } from './simple-page.module.scss'
import MainContainer from '../container/main'
import SEO from '../components/seo'
import Container from '../components/container'
import Contact from '../components/contact'

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark

  const isBlogArticle = () => {
    const { slug } = frontmatter
    const regex = new RegExp('/blog/')

    return regex.test(slug)
  }

  return (
    <MainContainer>
      <SEO title={`${frontmatter.title}`} />
      <Container>
        <article className={`${simplePageContainer} ${isBlogArticle() ? 'blog' : ''}`}>
          <section className="article-head">
            {isBlogArticle() && (
              <div className="action-wrapper">
                <Link to="/blog" className="action-item">
                  View all articles
                </Link>
              </div>
            )}
            <h1 className="title">{frontmatter.title}</h1>

            {frontmatter.date || frontmatter.author ? (
              <span className="meta">
                {frontmatter.date && <span className="item">{frontmatter.date}</span>}
                {frontmatter.author && <span className="item">By {frontmatter.author}</span>}
              </span>
            ) : null}

            {frontmatter.featuredImage && <GatsbyImage image={frontmatter.featuredImage.childImageSharp.fluid} />}
          </section>

          <section className="article-content" dangerouslySetInnerHTML={{ __html: html }} />
          {frontmatter.withContact && <Contact />}
        </article>
      </Container>
    </MainContainer>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        author
        withContact
        featuredImage {
          childImageSharp {
            gatsbyImageData(layout: FIXED)
          }
        }
      }
    }
  }
`
